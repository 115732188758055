import * as React from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ListItem, { ListItemButtonStyled, Menu } from "./ListItem/ListItem"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import EventIcon from "@mui/icons-material/Event"
import DomainAddIcon from "@mui/icons-material/DomainAdd"
import ListIcon from "@mui/icons-material/List"
import PublicIcon from "@mui/icons-material/Public"
import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import TocIcon from "@mui/icons-material/Toc"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt"
import CategoryIcon from "@mui/icons-material/Category"
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PersonIcon from "@mui/icons-material/Person"
import PeopleIcon from "@mui/icons-material/People"
import ClassIcon from "@mui/icons-material/Class"
import FmdGoodIcon from "@mui/icons-material/FmdGood"
import EditCalendarIcon from "@mui/icons-material/EditCalendar"
import { ListItemIcon, ListItemText } from "@mui/material"
import { useAppDispatch } from "../../../../store/app/hooks"
import { Logout } from "@mui/icons-material"
import { logout } from "../../../../store/features/user/userSlice"
import { useNavigate } from "react-router-dom"
import {
  setCloseAlert,
  setOpenAlert,
} from "../../../../store/features/alert/alertSlice"
import PaidIcon from "@mui/icons-material/Paid"

const ListMain = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const menus = [
    {
      name: "Tableau de board",
      title: "Tableau de board",
      icon: <DashboardIcon color="inherit" />,
      route: "/",
      permissions: [
        "askPaiment.view",
        "statisticBenefit.view",
        "statisticEvent.view",
        "statisticPlace.view",
        "statisticCustomer.view",
      ],
    },
    {
      name: "Utilisateurs",
      title: "Utilisateurs",
      icon: <PersonIcon />,
      route: "/user/list",
      isMain: true,
      permissions: ["customer.view"],
      subMenu: [
        {
          name: "Listes",
          title: "Liste des utilisateurs",
          icon: <RecentActorsIcon />,
          route: "user/list",
          permissions: ["customer.view"],
        },
      ],
    },
    {
      name: "Commissions",
      title: "Commissions",
      icon: <PaidIcon />,
      route: "/commission/list",
      isMain: true,
      permissions: [
        "commission.view",
        "commission.delete",
        "commission.create",
        "commission.change",
      ],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une commission",
          icon: <PaidIcon />,
          route: "/commission/add",
          permissions: ["commission.create"],
        },
        {
          name: "Listes",
          title: "Liste des commissions",
          icon: <ListIcon />,
          route: "/commission/list",
          permissions: [
            "commission.delete",
            "commission.create",
            "commission.change",
          ],
        },
      ],
    },
    {
      name: "Pays",
      title: "Pays",
      icon: <PublicIcon />,
      route: "/country/list",
      isMain: true,
      permissions: [
        "country.create",
        "country.view",
        "country.change",
        "country.delete",
      ],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un pays",
          icon: <FlagCircleIcon />,
          route: "/country/add",
          permissions: ["country.create"],
        },
        {
          name: "Listes",
          title: "Liste des pays",
          icon: <ListIcon />,
          route: "/country/list",
          permissions: ["country.view", "country.change", "country.delete"],
        },
      ],
    },
    {
      name: "Villes",
      title: "Villes",
      icon: <LocationCityIcon />,
      route: "/city/list",
      isMain: true,
      permissions: ["city.delete", "city.view", "city.create", "city.change"],
      subMenu: [
        {
          name: "Ajouter",
          icon: <DomainAddIcon />,
          route: "/city/add",
          title: "Ajouter une ville",
          permissions: ["city.create"],
        },
        {
          name: "Listes",
          title: "Liste des villes",
          icon: <TocIcon />,
          route: "/city/list",
          permissions: ["city.delete", "city.view", "city.change"],
        },
      ],
    },
    {
      name: "Lieux",
      title: "Lieux",
      icon: <FmdGoodIcon />,
      route: "/place/list",
      isMain: true,
      permissions: [
        "place.create",
        "place.delete",
        "place.view",
        "place.change",
        "noticePlace.delete",
        "noticePlace.view",
        "noticePlace.create",
        "noticePlace.change",
        "categoryPlace.delete",
        "categoryPlace.view",
        "categoryPlace.change",
        "categoryPlace.create",
      ],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un lieu",
          icon: <AddLocationAltIcon />,
          route: "/place/add",
          permissions: ["place.create"],
        },
        {
          name: "Listes",
          title: "Liste des lieux",
          icon: <FormatListNumberedIcon />,
          route: "/place/list",
          permissions: ["place.delete", "place.view", "place.change"],
        },
        {
          name: "Avis",
          title: "Liste des avis",
          icon: <FormatListNumberedIcon />,
          route: "/place-notice/list",
          permissions: [
            "noticePlace.delete",
            "noticePlace.view",
            "noticePlace.create",
            "noticePlace.change",
          ],
        },
        {
          name: "Catégories",
          icon: <CategoryIcon />,
          title: "Catégories de lieux",
          route: "/place-category/list",
          isMain: true,
          permissions: [
            "categoryPlace.delete",
            "categoryPlace.view",
            "categoryPlace.change",
            "categoryPlace.create",
          ],
          subMenu: [
            {
              name: "Ajouter",
              title: "Ajouter une catégorie de lieux",
              icon: <ClassIcon />,
              route: "place-category/add",
              permissions: ["categoryPlace.create"],
            },
            {
              name: "Listes",
              title: "Liste des catégories de lieux",
              icon: <ListIcon />,
              route: "place-category/list",
              permissions: [
                "categoryPlace.delete",
                "categoryPlace.view",
                "categoryPlace.change",
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Évenements",
      title: "",
      icon: <EventIcon />,
      route: "/event/list",
      isMain: true,
      permissions: [
        "event.create",
        "event.delete",
        "event.view",
        "event.change",
        "noticeEvent.delete",
        "noticeEvent.view",
        "noticeEvent.create",
        "noticeEvent.change",
        "categoryEvent.delete",
        "categoryEvent.view",
        "categoryEvent.change",
        "categoryEvent.create",
      ],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un évenement",
          icon: <EditCalendarIcon />,
          route: "event/add",
          permissions: ["event.create"],
        },
        {
          name: "Listes",
          title: "Liste des évenements",
          icon: <ListIcon />,
          route: "event/list",
          permissions: ["event.delete", "event.view", "event.change"],
        },
        {
          name: "Avis",
          title: "Liste des avis",
          icon: <FormatListNumberedIcon />,
          route: "/event-notice/list",
          permissions: [
            "noticeEvent.delete",
            "noticeEvent.view",
            "noticeEvent.create",
            "noticeEvent.change",
          ],
        },
        {
          name: "Catégories",
          icon: <CategoryIcon />,
          title: "Catégories d'évenement",
          route: "/event-category/list",
          isMain: true,
          permissions: [
            "categoryEvent.delete",
            "categoryEvent.view",
            "categoryEvent.change",
            "categoryEvent.create",
          ],
          subMenu: [
            {
              name: "Ajouter",
              title: "Ajouter une catégorie d'évenement",
              icon: <ClassIcon />,
              route: "event-category/add",
              permissions: ["categoryEvent.create"],
            },
            {
              name: "Listes",
              title: "Liste des catégories d'évenement",
              icon: <ListIcon />,
              route: "event-category/list",
              permissions: [
                "categoryEvent.delete",
                "categoryEvent.view",
                "categoryEvent.change",
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Administrateurs",
      icon: <SupervisedUserCircleIcon />,
      title: "Liste des lieux",
      route: "/admin/list",
      isMain: true,
      permissions: [
        "admin.delete",
        "admin.view",
        "admin.create",
        "admin.change",
      ],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un administrateur",
          icon: <PersonAddIcon />,
          route: "admin/add",
          permissions: ["admin.create"],
        },
        {
          name: "Listes",
          icon: <PeopleIcon />,
          title: "Liste des administrateurs",
          route: "admin/list",
          permissions: ["admin.change", "admin.delete", "admin.view"],
        },
      ],
    },
  ]
  const handleLogout = () => {
    sessionStorage.clear()
    dispatch(logout())
    dispatch(setCloseAlert())
    navigate("/login")
  }

  const handleOpenDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: handleLogout,
        message: "Êtes-vous vraiment sûr de vouloir se déconnecter ?",
        isLoading: false,
      }),
    )
  }

  return (
    <React.Fragment>
      {menus.map((_el: Menu) => (
        <ListItem menu={_el} key={_el.name} />
      ))}
      <ListItemButtonStyled sx={{ mx: 1 }} onClick={() => handleOpenDialog()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={"Déconnexion"} />
      </ListItemButtonStyled>
    </React.Fragment>
  )
}

export default ListMain
