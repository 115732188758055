import { useNavigate } from "react-router-dom"
import { getAuthToken } from "../../utils/authToken"
import { havePermissions } from "../../utils/permissions"

interface Props {
  children: React.ReactNode
  isProtected?: boolean
  permissions: string[]
  isChecked?: boolean
}

export function ProtectedRoute({
  children,
  isProtected = true,
  permissions,
  isChecked = true,
}: Props) {
  const navigate = useNavigate()
  const token = getAuthToken()

  if (isProtected && !token) {
    setTimeout(() => navigate("/login"), 0)
    return null
  }

  if (
    (!isProtected && token) ||
    (isProtected && !havePermissions(permissions) && isChecked)
  ) {
    setTimeout(() => navigate("/"), 0)
    return null
  }

  return children
}
