import { useCallback, useMemo, useState } from "react"
import { MRT_ColumnDef } from "material-react-table"
import { graphql } from "../../../gql"
import {
  ActionTransaction,
  PaymentRequest,
  SortOrder,
  WalletType,
  QueryMode,
} from "../../../gql/graphql"
import { Pagination } from "../../../types/Pagination"
import { useAppDispatch } from "../../../store/app/hooks"
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../store/features/alert/alertSlice"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import Loader from "../../Common/Loader/Loader"
import CustomTable from "../../Common/CustomTable/CustomTable"
import { Box, Button, Tooltip } from "@mui/material"
import { havePermissions } from "../../../utils/permissions"

export const PAYMENTREQUESTS = graphql(`
  query PaymentRequests(
    $where: PaymentRequestWhereInput
    $orderBy: [PaymentRequestOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregatePaymentRequestWhere2: PaymentRequestWhereInput
  ) {
    paymentRequests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      userId
      operator
      createdAt
      price
      phoneNumber
      rib
      walletType
      accountNumber
      username
      description
      user {
        id
        Country {
          id
          currency
        }
      }
      events {
        id
        title
      }
    }
    aggregatePaymentRequest(where: $aggregatePaymentRequestWhere2) {
      _count {
        _all
      }
    }
  }
`)

const VALIDATE_USER_PAIMENT = graphql(`
  mutation ValidUserPaiment($paimentId: Float!, $userId: Float!) {
    validUserPaiment(paimentId: $paimentId, userId: $userId) {
      id
    }
  }
`)

const DELETE = graphql(`
  mutation DeleteOnePaymentRequest($where: PaymentRequestWhereUniqueInput!) {
    deleteOnePaymentRequest(where: $where) {
      id
    }
  }
`)

const ListAskPayment = () => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  })
  const [query, setQuery] = useState("")
  const dispatch = useAppDispatch()

  const queryName = useCallback(() => {
    return query
      ? {
          AND: [
            {
              action: {
                equals: ActionTransaction.Asking,
              },
            },
            {
              OR: [
                {
                  user: {
                    is: {
                      OR: [
                        {
                          id: {
                            equals: Number(query),
                          },
                        },
                        {
                          lastName: {
                            contains: query,
                            mode: QueryMode.Insensitive,
                          },
                        },
                        {
                          name: {
                            contains: query,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  id: {
                    equals: Number(query),
                  },
                },
                {
                  event: {
                    is: {
                      OR: [
                        {
                          id: {
                            equals: Number(query),
                          },
                        },
                        {
                          title: {
                            contains: query,
                            mode: QueryMode.Insensitive,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        }
      : {
          action: {
            equals: ActionTransaction.Asking,
          },
        }
  }, [query])

  const { loading, data, refetch } = useQuery(PAYMENTREQUESTS, {
    variables: {
      take: pagination.size,
      skip: pagination.page * pagination.size,
      orderBy: [{ createdAt: SortOrder.Desc }],
      where: {
        ...queryName(),
      },
    },
  })

  const [validatePaimentUser] = useMutation(VALIDATE_USER_PAIMENT)
  const [deletePaimentUser] = useMutation(DELETE)

  const style = { backgroundColor: "black", padding: 5 }

  const columns = useMemo<MRT_ColumnDef<PaymentRequest>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.createdAt
            ? moment(row.createdAt).format("DD MMM YYYY")
            : "Pas définie",
        header: "Date de demande",
        enableClickToCopy: true,
      },
      {
        accessorKey: "userId",
        header: "Id d'utilisateur",
        enableClickToCopy: true,
      },
      {
        accessorKey: "price",
        header: "Montant",
        enableClickToCopy: true,
      },
      {
        accessorKey: "user.Country.currency",
        header: "Devise",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => (
          <>
            {row?.events.length ? (
              <>{row?.events.toString()}</>
            ) : (
              "Ne pas préciser"
            )}
          </>
        ),
        header: "Événement(s)",
      },
      {
        accessorFn: (row) =>
          row.walletType == WalletType.Bank
            ? "Banque"
            : row.walletType == WalletType.Mobile
            ? "Mobile money"
            : "Paypal",
        header: "Mode de paiment",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.walletType == WalletType.Bank ? (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Numero de compte:{" "}
                  <span style={style}>{row.accountNumber}</span>
                </li>
                <li>
                  RIB: <span style={style}>{row.rib}</span>
                </li>
              </ul>
            </div>
          ) : row.walletType == WalletType.Mobile ? (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Numéro de téléphone:{" "}
                  <span style={style}>{row.phoneNumber}</span>
                </li>
                <li>
                  Opérateur: <span style={style}>{row.operator}</span>
                </li>
              </ul>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Pseudo: <span style={style}>{row.username}</span>
                </li>
              </ul>
            </div>
          ),
        header: "Infomation de dépôt",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    setPagination(pagination)

  const onChangeSearchValue = (value: string) => setQuery(value)

  const handleValidate = (userId: number, paimentId: number) => {
    dispatch(setLoadingDelete(true))
    validatePaimentUser({
      variables: {
        userId,
        paimentId,
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le versment a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        refetch({
          take: pagination.size,
          skip: pagination.page * pagination.size,
          orderBy: { createdAt: SortOrder.Desc },
          where: {
            ...queryName(),
          },
        })
      },
    })
  }

  const handleDelete = (paimentId: number) => {
    dispatch(setLoadingDelete(true))
    deletePaimentUser({
      variables: {
        where: {
          id: paimentId,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le versment a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        refetch({
          take: pagination.size,
          skip: pagination.page * pagination.size,
          orderBy: { createdAt: SortOrder.Desc },
          where: {
            ...queryName(),
          },
        })
      },
    })
  }

  const handleShow = (userId: number, paimentId: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidate(userId, paimentId),
        message:
          "Êtes-vous vraiment sûr de vouloir valider cette de demande de versement ?",
        isLoading: false,
      }),
    )
  }

  const handleShowDelete = (paimentId: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleDelete(paimentId),
        message:
          "Êtes-vous vraiment sûr de vouloir supprimer cette de demande de versement ?",
        isLoading: false,
      }),
    )
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.paymentRequests || []}
      lableAddNew=""
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregatePaymentRequest._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={pagination.page}
      pageSize={pagination.size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      isHaveAction={false}
      isCustomAction={true}
      renderAction={({ row, table }) => (
        <Box sx={{ display: "flex" }}>
          {havePermissions(["askPaiment.validate"]) ? (
            <Tooltip title="Supprimer la demande">
              <Button
                variant="contained"
                color="error"
                sx={{ background: "red", mx: 2 }}
                onClick={() => handleShowDelete(row.original.id)}
              >
                Supprimer
              </Button>
            </Tooltip>
          ) : null}
          {havePermissions(["askPaiment.validate"]) ? (
            <Tooltip title="Valider la demande">
              <Button
                variant="contained"
                color="success"
                style={{ background: "green" }}
                onClick={() => handleShow(row.original.userId, row.original.id)}
              >
                Valider
              </Button>
            </Tooltip>
          ) : null}
        </Box>
      )}
      permissionChange=""
      permissionCreate=""
      permissionDelete=""
      permissionView=""
    />
  )
}

export default ListAskPayment
