import { ApolloCache, Reference } from "@apollo/client"
import { COMMISSION_FRAGMENT } from "../fragment/commission"

export const updateCacheCommission = ({
  cache,
  entryData,
  action,
}: {
  cache: ApolloCache<any>
  entryData: any
  action: "update" | "delete" | "add"
}) => {
  return cache.modify({
    fields: {
      commissions(existing = []) {
        const newCommissionRef = cache.writeFragment({
          data: entryData,
          fragment: COMMISSION_FRAGMENT,
        })
        switch (action) {
          case "add":
            return [...existing, newCommissionRef]
          case "delete":
            return [
              ...existing?.filter(
                (el: Reference | undefined) =>
                  el?.__ref !== newCommissionRef?.__ref,
              ),
            ]
          case "update":
            const index = existing.findIndex(
              (el: Reference | undefined) => el?.__ref !== newCommissionRef?.__ref,
            )
            const items = [...existing]
            if (index > -1) {
              items[index] = newCommissionRef
            }
            return items
        }
      },
      aggregateCommission(existing = {}) {
        let _all = existing?._count?.id
        switch (action) {
          case "add":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all + 1 : 0,
              },
            }
          case "delete":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all - 1 : 0,
              },
            }
        }
      },
    },
  })
}
