import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_CITIES = graphql(`
  query ListCities(
    $where: CityWhereInput
    $orderBy: [CityOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    cities(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
    }
  }
`)

interface Props {
  getCity: (city: any) => void
  cityDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
  countryId?: number
}
const CustomListCity = ({
  getCity,
  cityDefault,
  label = "Ville",
  required = true,
  multiple = false,
  countryId,
}: Props) => {
  const [city, setCity] = useState(cityDefault)

  const [queryCity, setQueryCity] = useState("")
  const [openCity, setOpenCity] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name_fr: { search: queryCity, mode: QueryMode.Insensitive } },
        { name_en: { search: queryCity, mode: QueryMode.Insensitive } },
        { name_fr: { endsWith: queryCity, mode: QueryMode.Insensitive } },
        { name_en: { endsWith: queryCity, mode: QueryMode.Insensitive } },
        { name_fr: { contains: queryCity, mode: QueryMode.Insensitive } },
        { name_en: { contains: queryCity, mode: QueryMode.Insensitive } },
        { name_fr: { startsWith: queryCity, mode: QueryMode.Insensitive } },
        { name_en: { startsWith: queryCity, mode: QueryMode.Insensitive } },
      ],
    }
    return queryCity ? OrClause : {}
  }, [queryCity])

  const { loading, data, fetchMore } = useQuery(LIST_CITIES, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name_fr: SortOrder.Asc },
      ...((queryCity || countryId) && {
        where: {
          ...(queryCity && { ...queryName() }),
          ...(countryId && {
            country: {
              is: {
                id: {
                  equals: countryId,
                },
              },
            },
          }),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryCity(query)
  }

  const handleCloseCategory = () => {
    setOpenCity(false)
  }
  const handleOpenCity = async () => {
    setOpenCity(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.cities.length,
      },
    })
  }

  useEffect(() => {
    if (city && cityDefault) {
      if (!Array.isArray(city) && !Array.isArray(cityDefault)) {
        if (!city?.value && cityDefault?.value) {
          setCity(cityDefault)
        }
      } else {
        setCity(cityDefault)
      }
    }
  }, [city, cityDefault])

  const dataCity = data?.cities

  const cities =
    loading || !dataCity
      ? []
      : dataCity.map((el) => ({ label: el.name_fr, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenCity}
      open={openCity}
      defaultValue={cityDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openCity ? cities : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setCity(value)
          getCity(value)
        }
      }}
      query={queryCity}
      value={city}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListCity
