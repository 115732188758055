import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import CustomTextFieldPassword from "../../Common/CustomTextFieldPassword/CustomTextFieldPassword"
import CustomUploadFile, {
  MinTypeFile,
} from "../../Common/CustomUploadFile/CustomUploadFile"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { useLazyQuery, useMutation } from "@apollo/client"
import { graphql } from "../../../gql"
import { NewFileInput, Role } from "../../../gql/graphql"
import { uploadFile } from "../../../utils/uploadFile"
import { updateCacheAdmin } from "../../../caches/updateCacheAdmin"
import Loader from "../../Common/Loader/Loader"
import { defaultPermissions } from "../../../utils/roles"
import TablesRoles from "./TableRoles/TablesRoles"

// Signin mutation
const addAdminMutation = graphql(`
  mutation SignupUser($signupInput: SignupInput!) {
    signupUser(signupInput: $signupInput) {
      token
      user {
        id
        phoneNumber
        email
        permissions
        role
        name
        lastName
        profile {
          name
          url
          id
        }
      }
    }
  }
`)

const updateAdminMutation = graphql(`
  mutation UpdateAdmin($updateUserInput: UpdateUserInput!) {
    updateUserAccount(updateUserInput: $updateUserInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        permissions
        profile {
          url
          id
          name
        }
        Country {
          id
          name_fr
        }
      }
    }
  }
`)

const getOneAdminQuery = graphql(`
  query getOneAdminQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      phoneNumber
      email
      role
      name
      lastName
      permissions
      lang
      profile {
        id
        url
        name
      }
      Country {
        id
        name_fr
      }
    }
  }
`)

export default function AddAdmin() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [signin, { loading }] = useMutation(addAdminMutation)
  const [permissions, setPermissions] = useState<string[]>(defaultPermissions)
  const [updateadmin, { loading: loadingUpdate }] =
    useMutation(updateAdminMutation)

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [country, setCountry] = useState<InfinityListItem>(defaultValue)
  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )
  const [user, setUser] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    lastName: "",
    password: "",
    rePassword: "",
  })

  const [getAdmin, { loading: loadingInit, error }] = useLazyQuery(
    getOneAdminQuery,
    {
      onCompleted(data) {
        const newUser = data.user
        setUser({
          email: newUser?.email || "",
          lastName: newUser?.lastName || "",
          phoneNumber: newUser?.phoneNumber || "",
          name: newUser?.name || "",
          password: "",
          rePassword: "",
        })
        if (newUser?.permissions) {
          setPermissions(newUser.permissions)
        }
        if (data?.user?.profile) {
          const { url, id } = data.user?.profile
          setDefaulPhoto({ url, id })
        }
        if (newUser?.Country) {
          const newcountry: InfinityListItem = {
            value: newUser?.Country.id.toString() || "",
            label: newUser?.Country.name_fr || "",
          }
          setCountry(newcountry)
        }
      },
    },
  )

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleUpdate = (profilePhoto?: NewFileInput) => {
    updateadmin({
      variables: {
        updateUserInput: {
          email: user.email,
          name: user.name,
          lastName: user.lastName,
          password: user.password,
          role: Role.Admin,
          id,
          profilePhoto: profilePhoto,
          countryId: Number(country.value),
          phoneNumber: user.phoneNumber ? `+${user.phoneNumber}` : "",
          permissions,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/admin/list")
      },
      update: (cache, { data }) => {
        updateCacheAdmin({
          action: "update",
          cache,
          entryData: data?.updateUserAccount.user,
        })
      },
    })
  }

  const handleSignup = (profilePhoto?: NewFileInput) => {
    signin({
      variables: {
        signupInput: {
          email: user.email,
          name: user.name,
          phoneNumber: `+${user.phoneNumber}`,
          lastName: user.lastName,
          password: user.password,
          role: Role.Admin,
          profilePhoto,
          countryId: country?.value ? Number(country.value) : undefined,
          permissions,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/admin/list")
      },
      update: (cache, { data }) => {
        updateCacheAdmin({
          action: "add",
          cache,
          entryData: data?.signupUser.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (user.password !== user.rePassword) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez vérifier le mot de passe et confirmer le mot de passe !!!",
        }),
      )
      return
    }
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          setOpenSnackbar({
            message,
          })
          setIsLoading(false)
        },
        cbOk: (file) => {
          if (id) {
            handleUpdate(file)
          } else {
            handleSignup(file)
          }
        },
        file,
        getPrecent: console.log,
      })
    } else {
      if (id) {
        handleUpdate()
      } else {
        handleSignup()
      }
    }
  }

  const onFileUpload = (file: any) => {
    setFile(file)
  }

  const onClearUpload = () => setFile(null)

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  useEffect(() => {
    if (id) {
      getAdmin({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter un admin
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  required={true}
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom"
                  fullWidth
                  variant="outlined"
                  value={user.name}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="lastName"
                  type="text"
                  onChange={handleInputChange}
                  label="Prénom"
                  fullWidth
                  value={user.lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  required={true}
                  label="Email"
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  fullWidth
                  value={user.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <ReactPhoneInput
                  label="Numéro de téléphone"
                  value={user.phoneNumber}
                  onChange={(value) =>
                    setUser((prev) => ({ ...prev, phoneNumber: value }))
                  }
                  enableSearch={true}
                  component={TextField}
                  enableAreaCodes={true}
                  enableClickOutside={true}
                  enableAreaCodeStretch={true}
                  enableTerritories={true}
                  enableLongNumbers={true}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomTextFieldPassword
                  name="password"
                  onChange={handleInputChange}
                  label="Mot de passe"
                  fullWidth
                  value={user.password}
                  required={!Boolean(id)}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomTextFieldPassword
                  label="Confirmation de mot de passe"
                  fullWidth
                  name="rePassword"
                  value={user.rePassword}
                  required={!Boolean(id)}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <CustomListCountry
                  countryDefault={country}
                  getCountry={getCountry}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUpload}
                  onFileUpload={onFileUpload}
                  label="Photo de profile"
                  id="photo-de-profile"
                  defaultUrl={defaulPhoto}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TablesRoles
                  permissions={permissions}
                  handlePermissions={setPermissions}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || isLoading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
