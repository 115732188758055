import React, { useRef } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Quill from "quill"
import "quill/dist/quill.core.css"
import "quill/dist/quill.bubble.css"
import "quill/dist/quill.snow.css"
import "quill-mention"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"

// Register alignment and image resize modules with Quill
const AlignStyle = Quill.import("attributors/style/align")
//@ts-ignore
Quill.register(AlignStyle, true)

interface Props {
  value: string
  label?: string
  handleChange: (key: string, value: string) => void
  name: string
  required?: boolean
}

const CustomTextarea: React.FC<Props> = ({
  value,
  label = "Description",
  handleChange,
  name,
  required,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const inputRef = useRef<ReactQuill>(null)

  const openModal = () => {
    setIsOpen(true)
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ]

  return (
    <>
      <div className="group-input">
        <label htmlFor="description">
          {label}
          {required ? "*" : ""}
        </label>
        <br />
        <ReactQuill
          value={value}
          ref={inputRef}
          onFocus={openModal}
          className="min-quill"
          placeholder="Description ..."
          style={{ backgroundColor: "white", color: "black" }}
          modules={modules}
          formats={formats}
        />
        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              background: "white",
            },
          }}
        >
          <DialogTitle id="form-dialog-title" color="black">
            {label}
          </DialogTitle>
          <DialogContent>
            <ReactQuill
              value={value}
              onChange={(v) => handleChange(name, v)}
              placeholder="Description ..."
              style={{
                backgroundColor: "white",
                color: "black",
              }}
              className="max-quill"
              modules={modules}
              formats={formats}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="success" variant="contained">
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default CustomTextarea
