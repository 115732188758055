const permissionsList = [
  {
    label: "Clients",
    delete: "customer.delete",
    view: "customer.view",
    create: "customer.create",
    change: "customer.change",
  },
  {
    label: "Commissions",
    delete: "commission.delete",
    view: "commission.view",
    create: "commission.create",
    change: "commission.change",
  },
  {
    label: "Pays",
    delete: "country.delete",
    view: "country.view",
    create: "country.create",
    change: "country.change",
  },
  {
    label: "Villes",
    delete: "city.delete",
    view: "city.view",
    create: "city.create",
    change: "city.change",
  },
  {
    label: "Lieux",
    delete: "place.delete",
    view: "place.view",
    create: "place.create",
    change: "place.change",
  },
  {
    label: "Catégories lieux",
    delete: "categoryPlace.delete",
    view: "categoryPlace.view",
    create: "categoryPlace.create",
    change: "categoryPlace.change",
  },
  {
    label: "Avis lieux",
    delete: "noticePlace.delete",
    view: "noticePlace.view",
    create: "noticePlace.create",
    change: "noticePlace.change",
  },

  {
    label: "Événements",
    delete: "event.delete",
    view: "event.view",
    create: "event.create",
    change: "event.change",
  },
  {
    label: "Catégories événements",
    delete: "categoryEvent.delete",
    view: "categoryEvent.view",
    create: "categoryEvent.create",
    change: "categoryEvent.change",
  },
  {
    label: "Avis événements",
    delete: "noticeEvent.delete",
    view: "noticeEvent.view",
    create: "noticeEvent.create",
    change: "noticeEvent.change",
  },

  {
    label: "Admins",
    delete: "admin.delete",
    view: "admin.view",
    create: "admin.create",
    change: "admin.change",
  },
  {
    label: "Demande de versement",
    delete: "askPaiment.delete",
    view: "askPaiment.view",
    create: "askPaiment.create",
    change: "askPaiment.change",
  },
  {
    label: "Statistique de bénéfices",
    delete: "statisticBenefit.delete",
    view: "statisticBenefit.view",
    create: "statisticBenefit.create",
    change: "statisticBenefit.change",
  },
  {
    label: "Statistique d'événement",
    delete: "statisticEvent.delete",
    view: "statisticEvent.view",
    create: "statisticEvent.create",
    change: "statisticEvent.change",
  },
  {
    label: "Statistique de lieux",
    delete: "statisticPlace.delete",
    view: "statisticPlace.view",
    create: "statisticPlace.create",
    change: "statisticPlace.change",
  },
  {
    label: "Statistique de client",
    delete: "statisticCustomer.delete",
    view: "statisticCustomer.view",
    create: "statisticCustomer.create",
    change: "statisticCustomer.change",
  },
]

export const permissionsPublication = [
  {
    label: "Publication d'évenement",
    value: "event.publish",
  },
  {
    label: "Validation d'évenement",
    value: "event.validate",
  },
  {
    label: "Ajout de controlleur",
    value: "event.addController",
  },
  {
    label: "Publication de l'avis d'événement",
    value: "noticeEvent.publish",
  },
  {
    label: "Publication de lieux",
    value: "place.publish",
  },
  {
    label: "Publication de l'avis de lieux",
    value: "noticePlace.publish",
  },
  {
    label: "Validation de versement",
    value: "askPaiment.validate",
  },
]
export const defaultPermissions = permissionsList.flatMap((permission) => [
  permission.delete,
  permission.view,
  permission.create,
  permission.change,
])

export default permissionsList

function getPermissions(actionType: string) {
  return defaultPermissions.filter((p) => p.includes(actionType))
}

export const permissionsCreate = getPermissions("create")
export const permissionsChange = getPermissions("change")
export const permissionsDelete = getPermissions("delete")
export const permissionsView = getPermissions("view")
