import "./App.css"
import React from "react"
import theme from "./theme"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CustomSnackbar from "./components/Common/CustomSnackbar/CustomSnackbar"
import CustomAlert from "./components/Common/CustomAlert/CustomAlert"
import Router from "./Router"
import { IntlProvider } from "react-intl"

type Mode = "light" | "dark"

const messagesInFrench = {
  myMessage: "Aujourd'hui, nous sommes le {ts, date, ::yyyyMMdd}",
}

function App() {
  let mode = "light" as Mode
  const customTheme = React.useMemo(() => theme(mode), [mode])
  return (
    <IntlProvider messages={messagesInFrench} locale="fr" defaultLocale="fr">
      <ThemeProvider theme={customTheme}>
        <Router />
        <CustomSnackbar />
        <CustomAlert />
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App
