import React from "react"
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  CircularProgress,
} from "@mui/material"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import useLang from "../../../hooks/useLang"

interface CardTimerProps {
  eventId: number
  onPress: () => void
}

const STATISTIC = graphql(`
  query EventStatistic($where: EventWhereUniqueInput!) {
    event(where: $where) {
      id
      organisatorStatic {
        balance
        countCustomerUser
        countModeratorUser
        countTicket
        countTicketUnUsed
        countTicketUsed
        currency
        numberPeopleMax
        countNbView
        netBenefits
      }
    }
  }
`)

const EventStatistic: React.FC<CardTimerProps> = ({ eventId, onPress }) => {
  const { data, loading } = useQuery(STATISTIC, {
    variables: {
      where: {
        id: eventId,
      },
    },
  })
  const { getPrice } = useLang()

  const items = React.useMemo(
    () => [
      {
        key: 1,
        name: "Nb tickets",
        value: data?.event?.organisatorStatic?.countTicket || "Pas encore",
      },
      {
        key: 2,
        name: "Nb tickets sacnnés",
        value: data?.event?.organisatorStatic?.countTicketUsed || "Pas encore",
      },
      {
        key: 3,
        name: "Nb tickets non sacnnés",
        value:
          data?.event?.organisatorStatic?.countTicketUnUsed || "Pas encore",
      },
      {
        key: 4,
        name: "Nb participants",
        value:
          data?.event?.organisatorStatic?.countCustomerUser || "Pas encore",
      },
      {
        key: 8,
        name: "Nb de vue",
        value: data?.event?.organisatorStatic?.countNbView || "Pas encore",
      },
      {
        key: 5,
        name: "Nb de moderateur",
        value:
          data?.event?.organisatorStatic?.countModeratorUser || "Pas encore",
      },
      {
        key: 6,
        name: "Nb de personne max",
        value: data?.event?.organisatorStatic?.numberPeopleMax || "Pas définie",
      },
      {
        key: 7,
        name: "Balance",
        value: getPrice({
          price: data?.event?.organisatorStatic?.balance || 0,
          currency: data?.event?.organisatorStatic?.currency,
        }),
        style: { fontWeight: "bold" },
      },
      {
        key: 8,
        name: "Bénéfice net",
        value: getPrice({
          price: data?.event?.organisatorStatic.netBenefits || 0,
          currency: data?.event?.organisatorStatic.currency,
        }),
        style: { fontWeight: "bold" },
      },
    ],
    [data, eventId, loading],
  )

  if (loading) return <CircularProgress sx={{ color: "white", mt: 2 }} />

  return (
    <Card sx={{ mx: 2, backgroundColor: "white", boxShadow: 3 }}>
      <CardContent>
        <h5 style={{ color: "black", fontSize: "15px" }}>
          Statistique: {new Date().toLocaleDateString()}{" "}
          {new Date().toLocaleTimeString()}
        </h5>
        <Box my={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" color="textSecondary">
                      Étiquette
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1" color="textSecondary">
                      Valeur
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.key}>
                    <TableCell>
                      <Typography variant="body2" color="textPrimary">
                        {item.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        sx={item.style}
                      >
                        {item.value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" justifyContent="center" my={2}>
          <Button variant="contained" color="primary" onClick={onPress}>
            Fermer
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default EventStatistic
