import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ListUserInEvent from "../ListUserInEvent"

const UserInEventModal = ({ eventId }: { eventId: number }) => {
  const [isModalUserVisible, setIsModalUserVisible] = useState(false)
  const handleClose = () => {
    setIsModalUserVisible(false)
  }
  return (
    <>
      <Dialog
        open={isModalUserVisible}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { backgroundColor: "black", color: "white" },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleClose}
              sx={{ backgroundColor: "white", color: "black", marginRight: 1 }}
            >
              <CloseIcon />
            </IconButton>
            <Box flex={1} textAlign="center">
              <Typography variant="h6" color="white" noWrap>
                Les participants
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ flex: 1 }}>
            <ListUserInEvent eventId={eventId} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="text" onClick={() => setIsModalUserVisible(true)}>
        Voir les particpants
      </Button>
    </>
  )
}

export default UserInEventModal
