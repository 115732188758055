import { getPermissions, getUserEmail } from "./authToken"

export const havePermissions = (keyItems: string[] = []) => {
  const permissions = getPermissions()
  let tmp = false
  for (let index = 0; index < keyItems.length; index++) {
    const element = keyItems[index]
    if (permissions.includes(element)) {
      tmp = true
      break
    }
  }
  return tmp
}

export const whereInputClause = (value: any) => {
  const isAdmin = getUserEmail() === "admin@lebar-it.com"
  return isAdmin ? {} : value
}

export const isSuperAdmin = () => {
  const isAdmin = getUserEmail() === "admin@lebar-it.com"
  return isAdmin
}