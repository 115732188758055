import { useCallback, useState, useMemo } from "react"
import { useQuery } from "@apollo/client"
import TextField from "@mui/material/TextField"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, Typography } from "@mui/material"
import { graphql } from "../../../gql"
import {
  QueryMode,
  SortOrder,
  UserInEvent,
  UserRole,
} from "../../../gql/graphql"

const USERS_IN_EVENT = graphql(`
  query usersInEvent(
    $where: EventWhereUniqueInput!
    $userInEventWhere2: UserInEventWhereInput
    $orderBy: [UserInEventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $eventId: Int!
    $countUserTicketUnUseByEventEventId2: Int!
    $countUserTicketUseByEventEventId2: Int!
    $aggregateUserInEventWhere2: UserInEventWhereInput
  ) {
    event(where: $where) {
      id
      isFree
      userInEvent(
        where: $userInEventWhere2
        orderBy: $orderBy
        take: $take
        skip: $skip
      ) {
        id
        userRole
        user {
          id
          name
          lastName
          countUserTicketByEvent(eventId: $eventId)
          countUserTicketUnUseByEvent(
            eventId: $countUserTicketUnUseByEventEventId2
          )
          countUserTicketUseByEvent(eventId: $countUserTicketUseByEventEventId2)
        }
      }
    }
    aggregateUserInEvent(where: $aggregateUserInEventWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ListUserInEvent = ({ eventId }: { eventId: number }) => {
  const [query, setQuery] = useState("")
  const size = 10

  const variables = useMemo(
    () => ({
      eventId,
      countUserTicketUnUseByEventEventId2: eventId,
      countUserTicketUseByEventEventId2: eventId,
      where: { id: eventId },
      aggregateUserInEventWhere2: {
        eventId: { equals: eventId },
        userRole: { equals: UserRole.Guest },
        ...(query && {
          user: {
            is: {
              OR: [
                { name: { contains: query, mode: QueryMode.Insensitive } },
                { lastName: { contains: query, mode: QueryMode.Insensitive } },
              ],
            },
          },
        }),
      },
      orderBy: [
        { user: { tickets: { _count: SortOrder.Desc } } },
        { createdAt: SortOrder.Desc },
      ],
      userInEventWhere2: {
        userRole: { equals: UserRole.Guest },
        ...(query && {
          user: {
            is: {
              OR: [
                { name: { contains: query, mode: QueryMode.Insensitive } },
                { lastName: { contains: query, mode: QueryMode.Insensitive } },
              ],
            },
          },
        }),
      },
      take: size,
      skip: 0,
    }),
    [eventId, query],
  )

  const { data, loading, fetchMore } = useQuery(USERS_IN_EVENT, { variables })

  const handleLoadMore = useCallback(() => {
    const currentCount = data?.event?.userInEvent?.length || 0
    const totalCount = data?.aggregateUserInEvent._count?._all || 0

    if (currentCount < totalCount && !loading) {
      fetchMore({
        variables: { ...variables, skip: currentCount },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return {
            ...prev,
            event: {
              ...prev.event,
              id: fetchMoreResult.event?.id as number,
              isFree: fetchMoreResult.event?.isFree as boolean,
              userInEvent: [
                ...((prev.event?.userInEvent as unknown as UserInEvent[]) ||
                  []),
                ...((fetchMoreResult.event
                  ?.userInEvent as unknown as UserInEvent[]) || []),
              ],
            },
          }
        },
      })
    }
  }, [data, loading, fetchMore, variables])

  return (
    <Box sx={{ bgcolor: "black", color: "white", p: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Recherche..."
        InputProps={{ style: { color: "white" } }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "white" },
            "&:hover fieldset": { borderColor: "white" },
          },
          mb: 2,
        }}
      />
      <List sx={{ maxHeight: 600, overflow: "auto" }} onScroll={handleLoadMore}>
        {data?.event?.userInEvent.length ? (
          data?.event?.userInEvent?.map((item) => (
            <ListItem
              key={item.id}
              style={{ background: "gray", marginBottom: 4, borderRadius: 10 }}
            >
              <Box>
                <Typography>{`ID: ${item.user.id}`}</Typography>
                <Typography>{`Prénom: ${item.user.lastName}`}</Typography>
                <Typography>{`Nb tickets: ${item.user.countUserTicketByEvent}`}</Typography>
                <Typography>{`Nb tickets scannés: ${item.user.countUserTicketUseByEvent}`}</Typography>
                <Typography>{`Nb tickets non scannés: ${item.user.countUserTicketUnUseByEvent}`}</Typography>
              </Box>
            </ListItem>
          ))
        ) : (
          <Box>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Il n'y a pas encore
            </Typography>
          </Box>
        )}
      </List>
      {loading && <CircularProgress sx={{ color: "white", mt: 2 }} />}
    </Box>
  )
}

export default ListUserInEvent
