import * as React from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import UserChart from "./UserChart/UserChart"
import PlaceChart from "./PlaceChart/PlaceChart"
import EventChart from "./EventChart/EventChart"
import GainChart from "./GainChart/GainChart"
import ListAskPayment from "./ListAskPayment/ListAskPayment"
import { havePermissions } from "../../utils/permissions"
import { Typography } from "@mui/material"

export default function LabTabs() {
  const [value, setValue] = React.useState("0")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const style = {
    background: "dimgrey",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
  }

  if (
    !Boolean(
      havePermissions(["statisticBenefit.view"]) &&
        havePermissions(["statisticEvent.view"]) &&
        havePermissions(["statisticPlace.view"]) &&
        havePermissions(["statisticCustomer.view"]) &&
        havePermissions(["askPaiment.view"]),
    )
  ) {
    return (
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          alignContent: "center",
          height: "80vh",
        }}
      >
        <Typography color="black" variant="h1">
          Vous ne pouvez pas voir le tableau de board
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "white",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <TabList
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            indicatorColor="primary"
            onChange={handleChange}
            TabIndicatorProps={{
              color: "#fff",
            }}
          >
            {havePermissions(["statisticBenefit.view"]) && (
              <Tab label="Benefices" value="0" />
            )}
            {havePermissions(["statisticEvent.view"]) && (
              <Tab label="Évenements" value="1" />
            )}
            {havePermissions(["statisticPlace.view"]) && (
              <Tab label="Lieux" value="2" />
            )}
            {havePermissions(["statisticCustomer.view"]) && (
              <Tab label="Utilisateurs" value="3" />
            )}
            {havePermissions(["askPaiment.view"]) && (
              <Tab label="Demande de versement" value="4" />
            )}
          </TabList>
        </Box>
        <TabPanel value="0" style={{ ...style }}>
          <GainChart />
        </TabPanel>
        <TabPanel value="1" style={style}>
          <EventChart />
        </TabPanel>
        <TabPanel value="2" style={style}>
          <PlaceChart />
        </TabPanel>
        <TabPanel value="3" style={{ ...style }}>
          <UserChart />
        </TabPanel>
        <TabPanel value="4" style={{ ...style }}>
          <ListAskPayment />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
