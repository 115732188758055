import { gql } from "@apollo/client"

export const PLACE_FRAGMENT = gql(`
  fragment PlaceFragment on Place {
    id
    latitude
    longitude
    name
    isPublish
    descriptions
    categories {
      id
      name
    }
    city {
      id
      name_fr
      country {
        id
        name_fr
      }
    }
    coverImage {
      id
      url
    }
  }
`)
