import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCommission } from "../../../caches/updateCacheCommission"
import Loader from "../../Common/Loader/Loader"
import {
  CommissionCategory,
  CommissionType,
  Frequency,
} from "../../../gql/graphql"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import CustomTextarea from "../../Common/CustomTextarea/CustomTextarea"

const GET_COMMISSION = graphql(`
  query Commission($where: CommissionWhereUniqueInput!) {
    commission(where: $where) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      category
      description_dj
      description_en
      description_fr
      description_mg
      description_wl
      title_dj
      title_en
      title_fr
      title_mg
      title_wl
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

const UPDATE_COMMISSION = graphql(`
  mutation UpdateCommission(
    $type: CommissionType!
    $commissionId: Int!
    $price: Float!
    $frequency: Frequency!
    $countryIds: [Int!]
    $category: CommissionCategory
    $description_dj: String
    $description_en: String
    $description_fr: String
    $description_mg: String
    $description_wl: String
    $title_dj: String
    $title_en: String
    $title_fr: String
    $title_mg: String
    $title_wl: String
  ) {
    updateCommission(
      type: $type
      commissionId: $commissionId
      price: $price
      frequency: $frequency
      countryIds: $countryIds
      category: $category
      description_dj: $description_dj
      description_en: $description_en
      description_fr: $description_fr
      description_mg: $description_mg
      description_wl: $description_wl
      title_dj: $title_dj
      title_en: $title_en
      title_fr: $title_fr
      title_mg: $title_mg
      title_wl: $title_wl
    ) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      category
      description_dj
      description_en
      description_fr
      description_mg
      description_wl
      title_dj
      title_en
      title_fr
      title_mg
      title_wl
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

const CREATE_COMMISSION = graphql(`
  mutation CreateCommission(
    $type: CommissionType!
    $price: Float!
    $frequency: Frequency!
    $countryIds: [Int!]
    $category: CommissionCategory
    $description_dj: String
    $description_en: String
    $description_fr: String
    $description_mg: String
    $description_wl: String
    $title_dj: String
    $title_en: String
    $title_fr: String
    $title_mg: String
    $title_wl: String
  ) {
    createCommission(
      type: $type
      price: $price
      frequency: $frequency
      countryIds: $countryIds
      category: $category
      description_dj: $description_dj
      description_en: $description_en
      description_fr: $description_fr
      description_mg: $description_mg
      description_wl: $description_wl
      title_dj: $title_dj
      title_en: $title_en
      title_fr: $title_fr
      title_mg: $title_mg
      title_wl: $title_wl
    ) {
      id
      frequency
      price
      type
      createdAt
      category
      updatedAt
      description_dj
      description_en
      description_fr
      description_mg
      description_wl
      title_dj
      title_en
      title_fr
      title_mg
      title_wl
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

export default function AddCommission() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [frequency, setFrequency] = useState(Frequency.Ponctuel)
  const [category, setCategory] = useState(CommissionCategory.Paid)
  const [commission, setCommission] = useState({
    price: "",
    description_dj: "",
    description_en: "",
    description_fr: "",
    description_mg: "",
    description_wl: "",
    title_dj: "",
    title_en: "",
    title_fr: "",
    title_mg: "",
    title_wl: "",
  })
  const [country, setCountry] = useState({ label: "", value: "" })
  const [create, { loading }] = useMutation(CREATE_COMMISSION)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_COMMISSION)

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const handleInputChange = (event: any) => {
    setCommission({
      ...commission,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputDescriptionChange = (key: string, value: string) => {
    setCommission({
      ...commission,
      [key]: value,
    })
  }

  const [getCommission, { loading: loadingInit }] = useLazyQuery(
    GET_COMMISSION,
    {
      onCompleted(data) {
        const commission = data?.commission
        setCommission({
          price: commission?.price?.toString() || "",
          description_dj: commission?.description_dj || "",
          description_en: commission?.description_en || "",
          description_fr: commission?.description_fr || "",
          description_mg: commission?.description_mg || "",
          description_wl: commission?.description_wl || "",
          title_dj: commission?.title_dj || "",
          title_en: commission?.title_en || "",
          title_fr: commission?.title_fr || "",
          title_mg: commission?.title_mg || "",
          title_wl: commission?.title_wl || "",
        })
        setFrequency(commission?.frequency || Frequency.Ponctuel)
        setCategory(commission?.category || CommissionCategory.Paid)
        if (commission?.countries?.length) {
          const newCountry: InfinityListItem = {
            value: commission?.countries[0]?.id.toString() || "",
            label: commission?.countries[0]?.name_fr || "",
          }
          setCountry(newCountry)
        }
      },
    },
  )

  const handleCreate = () => {
    create({
      variables: {
        ...commission,
        frequency: frequency,
        price: Number(commission.price),
        type: CommissionType.Create,
        countryIds: [Number(country.value)],
        category,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/commission/list")
      },
      update: (cache, { data }) => {
        updateCacheCommission({
          action: "add",
          cache,
          entryData: data?.createCommission,
        })
      },
    })
  }

  const handleUpdate = () => {
    update({
      variables: {
        ...commission,
        frequency: frequency,
        price: Number(commission.price),
        type: CommissionType.Create,
        commissionId: id,
        countryIds: [Number(country.value)],
        category,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/commission/list")
      },
      update: (cache, { data }) => {
        updateCacheCommission({
          action: "update",
          cache,
          entryData: data?.updateCommission,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  useEffect(() => {
    if (id) {
      getCommission({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  const handleAddFrequency = (frequency: Frequency) => {
    setFrequency(frequency)
  }

  const handleAddCategory = (category: CommissionCategory) => {
    setCategory(category)
  }

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter une commission fixe par pays
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomListCountry
                  countryDefault={country}
                  getCountry={getCountry}
                  required={true}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="price"
                  label="Commission fixe selon la devise de pays"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={commission.price}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  name="title_fr"
                  label="Titre en fr"
                  fullWidth
                  variant="outlined"
                  value={commission.title_fr}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="title_en"
                  label="Titre en ang"
                  fullWidth
                  variant="outlined"
                  value={commission.title_en}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="title_wl"
                  label="Titre en wl"
                  fullWidth
                  variant="outlined"
                  value={commission.title_wl}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="title_dj"
                  label="Titre en dj"
                  fullWidth
                  variant="outlined"
                  value={commission.title_dj}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="title_mg"
                  label="Titre en mg"
                  fullWidth
                  variant="outlined"
                  value={commission.title_mg}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12} pt={4}>
                <CustomTextarea
                  name="description_fr"
                  label="Déscription en fr"
                  value={commission.description_fr}
                  handleChange={handleInputDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomTextarea
                  name="description_en"
                  label="Déscription en ang"
                  value={commission.description_en}
                  handleChange={handleInputDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomTextarea
                  name="description_wl"
                  label="Déscription en wl"
                  value={commission.description_wl}
                  handleChange={handleInputDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomTextarea
                  name="description_dj"
                  label="Déscription en dj"
                  value={commission.description_dj}
                  handleChange={handleInputDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomTextarea
                  name="description_mg"
                  label="Déscription en mg"
                  value={commission.description_mg}
                  handleChange={handleInputDescriptionChange}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <Typography>Nature d'événement</Typography>
                <Box mt={2}>
                  <Chip
                    label="Payant"
                    onClick={() => handleAddCategory(CommissionCategory.Paid)}
                    sx={{ m: 1 }}
                    color={
                      category === CommissionCategory.Paid
                        ? "success"
                        : "primary"
                    }
                  />
                  <Chip
                    label="Gratuit"
                    onClick={() => handleAddCategory(CommissionCategory.Free)}
                    sx={{ m: 1 }}
                    color={
                      category === CommissionCategory.Free
                        ? "success"
                        : "primary"
                    }
                  />
                  <Chip
                    label="Référencement"
                    onClick={() => handleAddCategory(CommissionCategory.Seo)}
                    sx={{ m: 1 }}
                    color={
                      category === CommissionCategory.Seo
                        ? "success"
                        : "primary"
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography>Visibilité d'événement</Typography>
                <Box mt={2}>
                  <Chip
                    label="Ponctuelle"
                    onClick={() => handleAddFrequency(Frequency.Ponctuel)}
                    sx={{ m: 1 }}
                    color={
                      frequency === Frequency.Ponctuel ? "success" : "primary"
                    }
                  />
                  <Chip
                    label="Faible(5 jours)"
                    onClick={() => handleAddFrequency(Frequency.Once)}
                    sx={{ m: 1 }}
                    color={frequency === Frequency.Once ? "success" : "primary"}
                  />
                  <Chip
                    label="Moyenne(15 jours)"
                    onClick={() => handleAddFrequency(Frequency.Medium)}
                    sx={{ m: 1 }}
                    color={
                      frequency === Frequency.Medium ? "success" : "primary"
                    }
                  />
                  <Chip
                    label="Élevé(30 jours)"
                    onClick={() => handleAddFrequency(Frequency.High)}
                    sx={{ m: 1 }}
                    color={frequency === Frequency.High ? "success" : "primary"}
                  />
                  <Chip
                    label="3 mois"
                    onClick={() => handleAddFrequency(Frequency.Threemonths)}
                    sx={{ m: 1 }}
                    color={
                      frequency === Frequency.Threemonths
                        ? "success"
                        : "primary"
                    }
                  />
                  <Chip
                    label="6 mois"
                    onClick={() => handleAddFrequency(Frequency.Sixmonths)}
                    sx={{ m: 1 }}
                    color={
                      frequency === Frequency.Sixmonths ? "success" : "primary"
                    }
                  />
                  <Chip
                    label="1 an"
                    onClick={() => handleAddFrequency(Frequency.Oneyear)}
                    sx={{ m: 1 }}
                    color={
                      frequency === Frequency.Oneyear ? "success" : "primary"
                    }
                  />
                </Box>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
