import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectEventCategoryPagination,
  setQueryEventCategory,
  setPaginationEventCategory,
  selectEventCategoryQuery,
} from "../../../store/features/eventCategory/eventCategorySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, CategoryEvents, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheCategory } from "../../../caches/updateCacheCategory"
import CustomImage from "../../Common/CustomImage/CustomImage"

export const LIST_CATEGORYEVENTSS = graphql(`
  query CategoryEventss(
    $where: CategoryEventsWhereInput
    $aggregateCategoryEventsWhere2: CategoryEventsWhereInput
    $orderBy: [CategoryEventsOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    findManyCategoryEvents(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      name_en
      name_mg
      name_wl
      name_dj
      icon {
        url
        id
      }
      coverImage {
        url
        id
      }
    }
    aggregateCategoryEvents(where: $aggregateCategoryEventsWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_CATEGORYEVENTS = graphql(`
  mutation DeleteOneCategoryEvents($where: CategoryEventsWhereUniqueInput!) {
    deleteOneCategoryEvents(where: $where) {
      id
      name
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyCategoryEvents($where: CategoryEventsWhereInput) {
    deleteManyCategoryEvents(where: $where) {
      count
    }
  }
`)

const ListCategoryEvents = () => {
  const { page, size } = useAppSelector(selectEventCategoryPagination)
  const query = useAppSelector(selectEventCategoryQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces évenements ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name: { contains: query, mode: QueryMode.Insensitive } }],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_CATEGORYEVENTSS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { name: SortOrder.Asc },
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteCategoryEvents] = useMutation(DELETE_CATEGORYEVENTS)

  const columns = useMemo<MRT_ColumnDef<CategoryEvents>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name",
        header: "Nom en Fr",
        enableClickToCopy: true,
      },
      {
        accessorKey: "name_en",
        header: "Nom en En",
      },
      {
        accessorKey: "name_mg",
        header: "Nom en Mg",
      },
      {
        accessorKey: "name_wl",
        header: "Nom en Wl",
      },
      {
        accessorKey: "name_dj",
        header: "Nom en Dj",
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.icon?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Icône",
        enableEditing: false,
        enableColumnFilter: false,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEventCategory(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCategoryEvents({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La catégorie a été supprimée avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCategory({
          action: "delete",
          cache,
          entryData: data?.deleteOneCategoryEvents,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message:
          "Êtes-vous vraiment sûr de vouloir supprimer cette catégorie ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryEventCategory(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier une catégorie"))
    navigate(`/event-category/edit/${id}`)
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.findManyCategoryEvents || []}
      lableAddNew="Créer une nouvelle catégorie"
      rootLisName={"/event-category/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCategoryEvents._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleEdit={handleEdit}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
      permissionChange="categoryEvent.change"
      permissionCreate="categoryEvent.create"
      permissionDelete="categoryEvent.delete"
      permissionView="categoryEvent.view"
      handleSelecteds={setIds}
    />
  )
}

export default ListCategoryEvents
