import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import {
  selectEventCategoryPagination,
  setQueryEventCategory,
  setPaginationEventCategory,
  selectEventCategoryQuery,
} from "../../../store/features/eventCategory/eventCategorySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  Event,
  QueryMode,
  InvitationStatus,
  User,
  CommissionCategory,
} from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import CustomImage from "../../Common/CustomImage/CustomImage"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { getValue } from "../../../utils/methodeString"
import moment from "moment"
import { updateCacheEvent } from "../../../caches/updateCacheEvent"
import { Chip, TextField } from "@mui/material"
import AddControllers from "./AddControllers/AddControllers"
import { getUserEmail } from "../../../utils/authToken"
import UserModal from "./UserModal/UserModal"
import { havePermissions } from "../../../utils/permissions"
import DescriptionModal from "./DescriptionModal/DescriptionModal"
import { debounce } from "lodash"
import EventStatisticModal from "../../Common/EventStatistic/EventStatisticModal/EventStatisticModal"
import UserInEventModal from "../../Common/ListUserInEvent/UserInEventModal/UserInEventModal"

export const LIST_EVENTS = graphql(`
  query Events(
    $where: EventWhereInput
    $aggregateEventWhere2: EventWhereInput
    $orderBy: [EventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    events(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      latitude
      longitude
      numberPeopleMax
      isFreeEvent
      isEventCommissionPaid
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      frequency
      commissionCategory
      endedTime
      isFree
      priority
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
    aggregateEvent(where: $aggregateEventWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_EVENT = graphql(`
  mutation DeleteOneEvent($where: EventWhereUniqueInput!) {
    deleteOneEvent(where: $where) {
      id
      title
    }
  }
`)

const VALIDATE_EVENT = graphql(`
  mutation ValidateEvent($eventId: Float!, $isValidate: Boolean!) {
    validateEvent(eventId: $eventId, isValidate: $isValidate) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      priority
      frequency
      commissionCategory
      isFree
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const OPEN_TICKET_EVENT = graphql(`
  mutation UpdateOneEvent(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      frequency
      commissionCategory
      endedDate
      startedDate
      startedTime
      endedTime
      priority
      country {
        id
        name_fr
      }
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const PUBLISH_EVENT = graphql(`
  mutation PublishEvent($eventId: Float!, $isPublish: Boolean!) {
    publishEvent(eventId: $eventId, isPublish: $isPublish) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      priority
      frequency
      commissionCategory
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyEvent($where: EventWhereInput) {
    deleteManyEvent(where: $where) {
      count
    }
  }
`)

const UPDATE_ORDER = graphql(`
  mutation UpdateOneEventPriority(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      frequency
      commissionCategory
      endedTime
      isFree
      priority
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const UpdatePriority = ({
  cbOk,
  id,
  priority,
  loading,
}: {
  id: number
  priority: number
  cbOk: (id: number, priority: number) => void
  loading: boolean
}) => {
  const [priorityValue, setPriorityValue] = useState(String(priority))

  const handleChange = useCallback(
    debounce((newPriority: string) => {
      cbOk(id, Number(newPriority))
    }, 500),
    [id, cbOk],
  )

  useEffect(() => {
    setPriorityValue(String(priority))
  }, [priority])

  const onPriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setPriorityValue(newValue)
    handleChange(newValue)
  }

  return (
    <TextField
      id="outlined-basic-priority"
      label=""
      size="small"
      variant="outlined"
      value={priorityValue}
      disabled={loading}
      type="number"
      onChange={onPriorityChange}
    />
  )
}

const ListEvent = () => {
  const [event, setEvent] = useState<Event | null>(null)
  const [open, setOpen] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [descriptions, setdescriptions] = useState("")
  const [user, setUser] = useState<User | null | undefined>(null)
  const [openModalUser, setOpenModalUser] = useState(false)
  const { page, size } = useAppSelector(selectEventCategoryPagination)
  const query = useAppSelector(selectEventCategoryQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const email = getUserEmail()
  const [ids, setIds] = useState<number[]>([])

  const handleClose = () => setOpen(false)

  const handleOpenModalUser = (user?: User) => {
    setUser(user)
    setOpenModalUser(true)
  }

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)
  const [updatePriority, { loading: loadingPriority }] =
    useMutation(UPDATE_ORDER)

  const handleUpdatePriority = (id: number, priority: number) => {
    if (!loadingPriority) {
      updatePriority({
        variables: {
          data: {
            priority: {
              set: Math.abs(priority),
            },
          },
          where: {
            id,
          },
        },
        update: (cache, { data }) => {
          updateCacheEvent({
            action: "update",
            cache,
            entryData: data?.updateOneEvent,
          })
        },
        onCompleted: () => {
          dispatch(
            setOpenSnackbar({
              message: "La priorité de l'événement a été modifié avec succès",
              status: "success",
            }),
          )
        },
        onError: (err) => {
          const message = getErrorsAsString(err)
          dispatch(setCloseAlert())
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }
  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces évenements ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ title: { contains: query, mode: QueryMode.Insensitive } }],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_EVENTS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ priority: SortOrder.Desc }, { createdAt: SortOrder.Desc }],
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteEvent] = useMutation(DELETE_EVENT)
  const [publishEvent] = useMutation(PUBLISH_EVENT)
  const [validateEvent] = useMutation(VALIDATE_EVENT)
  const [openEventTicket] = useMutation(OPEN_TICKET_EVENT)

  const onCompleted = () => {
    dispatch(
      setOpenSnackbar({
        message: "La modification a été faite avec succès",
        status: "success",
      }),
    )
    dispatch(setLoadingDelete(false))
    dispatch(setCloseAlert())
  }

  const onError = (err: ApolloError) => {
    const message = getErrorsAsString(err)
    dispatch(setCloseAlert())
    dispatch(setOpenSnackbar({ message }))
  }

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    publishEvent({
      variables: {
        eventId: id,
        isPublish: !isPublish,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.publishEvent,
        })
      },
    })
  }

  const handleToogleOpenTicket = (
    id: number,
    isOpenTicket: boolean,
    commissionCategory: CommissionCategory,
  ) => {
    dispatch(setLoadingDelete(true))
    const isOpenTicketState = !isOpenTicket
    openEventTicket({
      variables: {
        data: {
          isOpenTicket: {
            set: isOpenTicketState,
          },
          commissionCategory: {
            set: isOpenTicketState
              ? commissionCategory
              : CommissionCategory.Seo,
          },
        },
        where: {
          id,
        },
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.updateOneEvent,
        })
      },
    })
  }

  const handleToogleValidate = (id: number, isValidate: boolean) => {
    dispatch(setLoadingDelete(true))
    validateEvent({
      variables: {
        eventId: id,
        isValidate: !isValidate,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.validateEvent,
        })
      },
    })
  }

  const handleShowAlertPublish = (
    id: number,
    isPublish: boolean,
    isFreeEvent: boolean,
    isValidate: boolean,
  ) => {
    if (!isValidate) {
      dispatch(
        setOpenSnackbar({
          message:
            "Cet événement doit être validé pour être publié, veuillez donc le valider avant de le publier.",
          status: "error",
        }),
      )
    } else if (!havePermissions(["event.publish"])) {
      dispatch(
        setOpenSnackbar({
          message:
            "Vous ne pouvez pas l'exonérer pour un événement. Veuillez contacter l'organisateur afin qu'il paie la commission de création de l'événement, afin que vous puissiez le publier.",
          status: "error",
        }),
      )
    } else {
      dispatch(
        setOpenAlert({
          handleValid: () => handleTooglePublish(id, isPublish),
          message:
            "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de cet événement ?",
          isLoading: false,
        }),
      )
    }
  }

  const handleShowAlertOpenTicket = (
    id: number,
    isOpenTicket: boolean,
    commissionCategory: CommissionCategory,
  ) => {
    dispatch(
      setOpenAlert({
        handleValid: () =>
          handleToogleOpenTicket(id, isOpenTicket, commissionCategory),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de bietterie de cet événement ?",
        isLoading: false,
      }),
    )
  }

  const handleShowAlertValidate = (id: number, isValidate: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleToogleValidate(id, isValidate),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de validation de cet événement ?",
        isLoading: false,
      }),
    )
  }

  const handleAddController = (event: Event) => {
    setEvent(event)
    setOpen(true)
  }

  const columns = useMemo<MRT_ColumnDef<Event>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row.id)}
            >
              {row.id}
            </span>
          </>
        ),
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => {
          if (havePermissions(["event.publish"])) {
            return (
              <UpdatePriority
                cbOk={handleUpdatePriority}
                id={row.id}
                loading={loadingPriority}
                priority={row.priority}
              />
            )
          }
          return <p>{row.priority}</p>
        },
        header: "Priorité",
        enableEditing: true,
        size: 150,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => (
          <>
            <EventStatisticModal eventId={row.id} />
          </>
        ),
        header: "Statistiques",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <UserInEventModal eventId={row.id} />
          </>
        ),
        header: "Participants",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isOpenTicket)}
              handleChange={() => {
                if (havePermissions(["event.publish"])) {
                  handleShowAlertOpenTicket(
                    row.id,
                    Boolean(row.isOpenTicket),
                    row.commissionCategory,
                  )
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de bietterie",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() => {
                if (havePermissions(["event.publish"])) {
                  handleShowAlertPublish(
                    row.id,
                    Boolean(row.isPublish),
                    Boolean(row.isFreeEvent),
                    Boolean(row.isValidate),
                  )
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isValidate)}
              handleChange={() => {
                if (havePermissions(["event.validate"])) {
                  handleShowAlertValidate(row.id, Boolean(row.isValidate))
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de validation",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>{row.isEventCommissionPaid ? "Payé" : "Non Payé"}</>
        ),
        header: "Commission Hub",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => getValue(row?.country?.name_fr),
        header: "Pays",
      },
      {
        accessorFn: (row) => (
          <>
            <p
              onClick={() => handleOpenModalUser(row.creator)}
              style={{ cursor: "pointer" }}
            >
              ID: {row?.creator?.id}
            </p>
            <p
              onClick={() => handleOpenModalUser(row.creator)}
              style={{ cursor: "pointer" }}
            >
              Nom / Prénom: {row?.creator?.name || row?.creator?.lastName}
            </p>
          </>
        ),
        header: "Organisateur",
      },
      {
        accessorFn: (row) => getValue(row?.place?.name),
        header: "Lieu",
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
      },
      {
        accessorFn: (row) => (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row.id)}
            >
              {row.title}
            </span>
          </>
        ),
        header: "Nom",
      },
      {
        accessorFn: (row) => (
          <>
            <span>{row.descriptions?.slice(0, 20)}...</span>
            <br />
            <span
              style={{ color: "green" }}
              onClick={() => {
                setdescriptions(row.descriptions || "")
                setOpenDescription(true)
              }}
            >
              Voir plus
            </span>
          </>
        ),
        header: "Déscription",
      },
      {
        accessorFn: (row) => <>{row.isFreeEvent ? "Gratuit" : "Payant"}</>,
        header: "Type d'événement",
        enableEditing: false,
        enableColumnFilter: false,
      },

      {
        accessorFn: (row) => row?.city?.name_fr,
        header: "Ville",
      },
      {
        accessorFn: (row) => (
          <>
            <Chip
              label="Ajouter"
              variant="outlined"
              onClick={() => {
                if (havePermissions(["event.addController"])) {
                  handleAddController(row)
                }
              }}
            />
          </>
        ),
        header: "Ajouter des controllers",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter((el) => el.invitationStatus === InvitationStatus.Valid)
              .map((el) => el.user.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs validés",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter(
                (el) => el.invitationStatus === InvitationStatus.Inprogress,
              )
              .map((el) => el.user.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs en attente",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => moment(row?.startedDate).format("DD/MMM/YYYY"),
        header: "Date de début",
      },
      {
        accessorFn: (row) => moment(row?.startedTime).format("LT"),
        header: "Heure de début",
      },
      {
        accessorFn: (row) => moment(row?.endedDate).format("DD/MMM/YYYY"),
        header: "Date de fin",
      },
      {
        accessorFn: (row) => moment(row?.endedTime).format("LT"),
        header: "Heure de fin",
      },
      {
        accessorFn: (row) =>
          row.categories && row.categories.length
            ? row.categories[0].name
            : null,
        header: "Catégorie",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEventCategory(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteEvent({
      variables: {
        where: { id },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'évenement a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "delete",
          cache,
          entryData: data?.deleteOneEvent,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cet évenement ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryEventCategory(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un évenement"))
    navigate(`/event/edit/${id}`)
  }

  if (loading && !query) return <Loader />

  return (
    <>
      <CustomTable
        columns={columns}
        data={data?.events || []}
        lableAddNew="Créer un nouveau évenement"
        rootLisName={"/event/add"}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregateEvent._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={page}
        pageSize={size}
        searchValue={query}
        onChangeSearchValue={onChangeSearchValue}
        handleDelete={handleOpenDeleteDialog}
        handleEdit={handleEdit}
        handleSelected={handleSelected}
        handleDeleteGroup={handleDeleteGroup}
        selectedIds={ids}
        permissionChange="event.change"
        permissionCreate="event.create"
        permissionDelete="event.delete"
        permissionView="event.view"
        handleSelecteds={setIds}
      />
      {event && (
        <AddControllers event={event} handleClose={handleClose} open={open} />
      )}
      <UserModal
        handleClose={() => setOpenModalUser(false)}
        open={openModalUser}
        user={user}
      />
      <DescriptionModal
        handleClose={() => setOpenDescription(false)}
        open={openDescription}
        description={descriptions}
      />
    </>
  )
}

export default ListEvent
