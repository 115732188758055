import "./App.css"
import { useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import AddCity from "./components/City/AddCity/AddCity"
import ListEvent from "./components/Event/ListEvent/ListEvent"
import ListCity from "./components/City/ListCity/ListCity"
import Dashboard from "./components/Dashboard/Dashboard"
import AddEvent from "./components/Event/AddEvent/AddEvent"
import Layout from "./components/Layout/Layout"
import Login from "./components/Login/Login"
import ListEventCategory from "./components/EventCategory/ListEventCategory/ListEventCategory"
import AddPlace from "./components/Place/AddPlace/AddPlace"
import ListPlace from "./components/Place/ListPlace/ListPlace"
import ListUser from "./components/User/ListUser/ListUser"
import AddEventCategory from "./components/EventCategory/AddEventCategory/AddEventCategory"
import AddCountry from "./components/Country/AddCountry/AddCountry"
import ListCountry from "./components/Country/ListCountry/ListCountry"
import AddAdmin from "./components/Admin/AddAdmin/AddAdmin"
import ListAdmin from "./components/Admin/ListAdmin/ListAdmin"
import { useAppDispatch } from "./store/app/hooks"
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute"
import { useMediaQuery, useTheme } from "@mui/material"
import { setOpen } from "./store/features/menu/menuSlice"
import AddPlaceCategory from "./components/PlaceCategory/AddPlaceCategory/AddPlaceCategory"
import ListPlaceCategory from "./components/PlaceCategory/ListPlaceCategory/ListPlaceCategory"
import ListNoticePlace from "./components/Place/ListNotice/ListNotice"
import ListNoticeEvent from "./components/Event/ListNotice/ListNotice"
import AddCommission from "./components/Commission/AddCommission/AddCommission"
import ListCommission from "./components/Commission/ListCommission/ListCommission"

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <ProtectedRoute isProtected={false} permissions={[]} isChecked={false}>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute permissions={[]} isChecked={false}>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute permissions={[]} isChecked={false}>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "event/add",
        element: (
          <ProtectedRoute permissions={["event.create"]}>
            <AddEvent />
          </ProtectedRoute>
        ),
      },
      {
        path: "event/edit/:id",
        element: (
          <ProtectedRoute permissions={["event.change"]}>
            <AddEvent />
          </ProtectedRoute>
        ),
      },
      {
        path: "event/list",
        element: (
          <ProtectedRoute
            permissions={["event.delete", "event.change", "event.view"]}
          >
            <ListEvent />
          </ProtectedRoute>
        ),
      },
      {
        path: "event-notice/list",
        element: (
          <ProtectedRoute
            permissions={[
              "noticeEvent.delete",
              "noticeEvent.change",
              "noticeEvent.view",
            ]}
          >
            <ListNoticeEvent />
          </ProtectedRoute>
        ),
      },
      {
        path: "event-category/add",
        element: (
          <ProtectedRoute permissions={["categoryEvent.create"]}>
            <AddEventCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "place-category/add",
        element: (
          <ProtectedRoute permissions={["categoryPlace.create"]}>
            <AddPlaceCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "event-category/edit/:id",
        element: (
          <ProtectedRoute permissions={["categoryEvent.change"]}>
            <AddEventCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "place-category/edit/:id",
        element: (
          <ProtectedRoute permissions={["categoryPlace.change"]}>
            <AddPlaceCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "event-category/list",
        element: (
          <ProtectedRoute
            permissions={[
              "categoryEvent.view",
              "categoryEvent.change",
              "categoryEvent.delete",
            ]}
          >
            <ListEventCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "place-category/list",
        element: (
          <ProtectedRoute
            permissions={[
              "categoryPlace.view",
              "categoryPlace.change",
              "categoryPlace.delete",
            ]}
          >
            <ListPlaceCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "city/add",
        element: (
          <ProtectedRoute permissions={["city.create"]}>
            <AddCity />
          </ProtectedRoute>
        ),
      },
      {
        path: "city/edit/:id",
        element: (
          <ProtectedRoute permissions={["city.change"]}>
            <AddCity />
          </ProtectedRoute>
        ),
      },
      {
        path: "city/list",
        element: (
          <ProtectedRoute
            permissions={["city.view", "city.change", "city.delete"]}
          >
            <ListCity />
          </ProtectedRoute>
        ),
      },

      {
        path: "country/add",
        element: (
          <ProtectedRoute permissions={["country.create"]}>
            <AddCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/edit/:id",
        element: (
          <ProtectedRoute permissions={["country.change"]}>
            <AddCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/list",
        element: (
          <ProtectedRoute
            permissions={["country.view", "country.change", "country.delete"]}
          >
            <ListCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "commission/add",
        element: (
          <ProtectedRoute permissions={["commission.create"]}>
            <AddCommission />
          </ProtectedRoute>
        ),
      },
      {
        path: "commission/edit/:id",
        element: (
          <ProtectedRoute permissions={["commission.change"]}>
            <AddCommission />
          </ProtectedRoute>
        ),
      },
      {
        path: "commission/list",
        element: (
          <ProtectedRoute
            permissions={[
              "commission.view",
              "commission.delete",
              "commission.change",
            ]}
          >
            <ListCommission />
          </ProtectedRoute>
        ),
      },

      {
        path: "place/add",
        element: (
          <ProtectedRoute permissions={["place.create"]}>
            <AddPlace />
          </ProtectedRoute>
        ),
      },
      {
        path: "place/edit/:id",
        element: (
          <ProtectedRoute permissions={["place.change"]}>
            <AddPlace />
          </ProtectedRoute>
        ),
      },
      {
        path: "place/list",
        element: (
          <ProtectedRoute
            permissions={["place.view", "place.delete", "place.change"]}
          >
            <ListPlace />
          </ProtectedRoute>
        ),
      },
      {
        path: "place-notice/list",
        element: (
          <ProtectedRoute
            permissions={[
              "noticePlace.view",
              "noticePlace.delete",
              "noticePlace.change",
            ]}
          >
            <ListNoticePlace />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/list",
        element: (
          <ProtectedRoute permissions={["customer.view"]}>
            <ListUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/add",
        element: (
          <ProtectedRoute permissions={["admin.create"]}>
            <AddAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/edit/:id",
        element: (
          <ProtectedRoute permissions={["admin.change"]}>
            <AddAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/list",
        element: (
          <ProtectedRoute
            permissions={["admin.change", "admin.view", "admin.delete"]}
          >
            <ListAdmin />
          </ProtectedRoute>
        ),
      },
    ],
  },
])

function Router() {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    dispatch(setOpen(!isMobile))
  }, [])

  return <RouterProvider router={router} />
}

export default Router
