import { gql } from "@apollo/client"

export const COMMISSION_FRAGMENT = gql(`
  fragment CommissionsFragment on Commissions {
    id
    frequency
    price
    type
    createdAt
    category
    updatedAt
    description_dj
    description_en
    description_fr
    description_mg
    description_wl
    title_dj
    title_en
    title_fr
    title_mg
    title_wl
    countries {
      id
      name_fr
      currency
    }
  }
`)
